@layer utilities {
  .shadow-bottom {
    position: relative;
  }

  .shadow-inner-bottom {
    @apply overflow-hidden relative;
  }

  .shadow-bottom::after,
  .shadow-inner-bottom::after {
    @apply absolute rounded-full;

    content: '';
  }

  .shadow-bottom::after,
  .shadow-inner-bottom::after {
    @apply max-w-site m-auto inset-x-0 w-9/10 z-0;

    height: calc(20rem / 16);
    box-shadow: 0 0 calc(20rem / 16) rgba(0, 0, 0, 0.15);
  }

  .shadow-bottom::after {
    bottom: 0;
    z-index: -1;
  }
}