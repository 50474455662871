.locale-uk-UA body,
.locale-uk-UA .font-sans {
  font-family: Trident, "Noto Sans", Inter, Helvetica, "Arial Unicode", sans-serif !important;
}

.locale-uk-UA .font-brand {
  font-family: Trident, "Noto Serif", Newsreader, 'Times New Roman', Times, serif !important;
}

.locale-uk-UA .font-cursive {
  font-family: Trident, 'Happy Few Cyrillic', Newsreader, Verdana, Arial, sans-serif;
}
