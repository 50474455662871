/* purgecss start ignore */
.sticky-bottom .sticky-inner-wrapper {
  top: initial !important;
  bottom: 0;
}

@media (--r-maxL) {
  .sticky-bottom--resortNavTabs .sticky-inner-wrapper {
    top: initial !important;
    bottom: calc((var(--priceRemoteStickyHeight) + 40px));
  }
}

/* purgecss end ignore */