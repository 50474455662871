/* purgecss start ignore */
.hamburger {
  position: relative;
}

.hamburger-span,
.hamburger-span::before,
.hamburger-span::after {
  @apply bg-current block rounded-4;

  width: 22px;
  height: 2px;
}

.hamburger-span {
  transition: background-color linear 0.2s 0.25s;
}

.hamburger-span.hamburger-span-active {
  @apply bg-transparent;
}

.hamburger-span.hamburger-span-active {
  transition: background-color linear 0.2s;
}

.hamburger-span::before,
.hamburger-span::after {
  position: absolute;
  content: '';
  transition: transform ease-in 0.2s, top ease-in 0.2s 0.2s;
}

.hamburger-span::before {
  top: -6px;
}

.hamburger-span::after {
  top: 6px;
}

.hamburger-span.hamburger-span-active::before,
.hamburger-span.hamburger-span-active::after {
  transition: top ease-in 0.2s, transform ease-in 0.2s 0.2s;
}

.hamburger-span.hamburger-span-active::before {
  top: 0;
  transform: rotate(-45deg);
}

.hamburger-span.hamburger-span-active::after {
  top: 0;
  transform: rotate(45deg);
}

/* purgecss end ignore */