.locale-zh-CN body,
.locale-zh-CN .font-sans {
  font-family: Trident, "Microsoft YaHei", Inter, Helvetica, "Arial Unicode", sans-serif !important;
}

.locale-zh-CN .font-brand {
  font-family: Trident, "Microsoft YaHei", Newsreader, 'Times New Roman', Times, serif !important;
}

.locale-zh-CN .overflow-hidden > .leading-tighter {
  line-height: 1.5 !important; /* some cn glyphs are pretty tall */
}
