/* purgecss start ignore */
.mt-navigation {
  margin-top: var(--NavigationNarrow-height) !important;
}

@media (--r-minL) {
  .mt-navigation {
    margin-top: var(--NavigationLarge-height) !important;
  }
}

/* purgecss end ignore */