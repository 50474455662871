/* purgecss start ignore */
[dir="ltr"] .comparisonTableShadow {
  left: var(--comparisonTableFirstColumnWidth);
  background: linear-gradient(90deg, #eceaea 0%, rgba(255, 255, 255, 0) 100%);
}
  
[dir="rtl"] .comparisonTableShadow {
  right: var(--comparisonTableFirstColumnWidth);
  background: linear-gradient(-90deg, #eceaea 0%, rgba(255, 255, 255, 0) 100%);
}
/* purgecss end ignore */
