/* purgecss start ignore */
.corner-mark-tr-turquoise::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 22px solid color-mod(theme('colors.turquoise'));
  border-left: 22px solid transparent;
  content: '';
}

.corner-mark-tr-turquoise-sm::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 11px solid color-mod(theme('colors.turquoise'));
  border-left: 11px solid transparent;
  content: '';
}

/* purgecss end ignore */