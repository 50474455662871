/* purgecss start ignore */
/* a11y : hide outlined focus only for mouse */
.js-focus-visible *:focus:not(.focus-visible):not([data-focus-visible-added]) {
  outline: none;
}

/* prettier-ignore */
.js-focus-visible [data-focus-visible-added].focus-visible:focus + .outline-custom-sibling,
.js-focus-visible [data-focus-visible-added].focus-visible:not(input):not(textarea):not(select):focus,
.js-focus-visible .outline-custom.focus-visible:focus,
.js-focus-visible .outline-custom:focus-within {
  box-shadow: 0 0 3px 3px dodgerblue;
  outline: none;
}
/* purgecss end ignore */
