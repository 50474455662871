.locale-ru-RU body,
.locale-ru-RU .font-sans {
  font-family: Trident, "Noto Sans", Inter, Helvetica, "Arial Unicode", sans-serif !important;
}

.locale-ru-RU .font-brand {
  font-family: Trident, "Noto Serif", Newsreader, 'Times New Roman', Times, serif !important;
}

.locale-ru-RU .overflow-hidden > .leading-tighter {
  line-height: 1.5 !important; /* russian glyphs are pretty tall too apparently */
}

.locale-ru-RU .font-cursive {
  font-family: Trident, 'Happy Few Cyrillic', Newsreader, Verdana, Arial, sans-serif;
}
