@layer utilities {
  .decorator-match .match {
    font-weight: bold;
  }

  .direction-ltr {
    direction: ltr;
  }

  .direction-rtl {
    direction: rtl;
  }

  .icon-monochrome svg * {
    fill: currentColor !important;
  }

  .list-disc {
    position: relative;
    list-style-type: none !important;
    padding-inline-start: 0.75rem;
  }

  .list-disc::before {
    position: absolute;
    content: '•';
    inset-inline-start: 0;
  }

  .overflow-wrap-break-word {
    hyphens: auto;
    overflow-wrap: break-word;
  }

  .ReactCollapse--collapse,
  .collapse-transition {
    transition: height 500ms;
  }

  .reset-decoration {
    text-decoration: none !important;
  }

  .scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .tap-highlight-color-transparent {
    -webkit-tap-highlight-color: transparent;
  }

  .text-initial {
    text-align: initial;
  }
}

/* scroll */
@layer utilities {

  /* area */
  .scroll-x {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .scroll-y {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* hidden */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }

  .scrollbar-hidden::-webkit-scrollbar,
  .scrollbar-hidden::-webkit-scrollbar-button {
    display: none;
  }

  .scrollbar-hidden-overlap {
    margin-bottom: -50px;
    padding-bottom: 50px;
  }

  /* scroll x last item margin hack */
  .scroll-x-ghost-m {
    position: relative;
  }

  .scroll-x-ghost-m::after {
    position: absolute;
    display: block;
    flex: none;
    width: var(--tw-scroll-x-ghost-margin);
    height: 1px;
    inset-inline-end: calc(var(--tw-scroll-x-ghost-margin) * -1);
    content: '';
  }

  .scroll-x-ghost-m-0 {
    --tw-scroll-x-ghost-margin: 0;
  }

  .scroll-x-ghost-m-10 {
    --tw-scroll-x-ghost-margin: 0.625rem;
  }

  .scroll-x-ghost-m-20 {
    --tw-scroll-x-ghost-margin: 1.25rem;
  }
}

/* animation */
@layer utilities {

  /* animation duration */
  .animation-duration-300 {
    --tw-animation-duration: 0.3s;
  }

  .animation-duration-500 {
    --tw-animation-duration: 0.5s;
  }

  .animation-duration-750 {
    --tw-animation-duration: 0.75s;
  }

  .animation-duration-infinite {
    --tw-animation-duration: infinite;
  }
}