/* purgecss start ignore */
.immersivePaddingTop {
  padding-top: calc(50rem / 16);
}

.immersivePaddingTop--higlight {
  padding-top: calc(40rem / 16);
}

.immersiveHeight--small {
  height: calc(220rem / 16);
}

.immersivePaddingBottom--small {
  padding-bottom: calc(60rem / 16);
}

.immersiveHeight--xsmall {
  height: calc(170rem / 16);
}

.immersivePaddingBottom--xsmall {
  padding-bottom: calc(40rem / 16);
}

.immersiveHeight--highlightSmall {
  height: calc(220rem / 16);
}

.immersivePaddingBottom--highlightSmall {
  padding-bottom: calc(60rem / 16);
}

.immersiveHeight--medium {
  height: calc(320rem / 16);
}

.immersivePaddingBottom--medium {
  padding-bottom: calc(60rem / 16);
}

.immersiveHeight--large {
  height: calc(420rem / 16);
}

.immersivePaddingBottom--large {
  padding-bottom: calc(60rem / 16);
}

.immersiveHeight--highlight {
  height: calc(480rem / 16);
}

.immersivePaddingBottom--highlight {
  padding-bottom: calc(40rem / 16);
}

.immersiveHeight--full {
  height: 100%;
}

.immersivePaddingBottom--full {
  padding-bottom: calc(60rem / 16);
}

@media (--r-minL) {
  .immersivePaddingTop {
    padding-top: calc(60rem / 16);
  }

  .immersivePaddingTop--higlight {
    padding-top: calc(80rem / 16);
  }

  .immersiveHeight--small {
    height: calc(340rem / 16);
  }

  .immersivePaddingBottom--small {
    padding-bottom: calc(80rem / 16);
  }

  .immersiveHeight--highlightSmall {
    height: calc(340rem / 16);
  }

  .immersivePaddingBottom--highlightSmall {
    padding-bottom: calc(80rem / 16);
  }

  .immersiveHeight--medium {
    height: calc(440rem / 16);
  }

  .immersivePaddingBottom--medium {
    padding-bottom: calc(100rem / 16);
  }

  .immersiveHeight--large {
    height: calc(540rem / 16);
  }

  .immersivePaddingBottom--large {
    padding-bottom: calc(115rem / 16);
  }

  .immersivePaddingBottom--highlight {
    padding-bottom: calc(70rem / 16);
  }

  .immersivePaddingBottom--full {
    padding-bottom: calc(115rem / 16);
  }
}
/* purgecss end ignore */
