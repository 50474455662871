.locale-ja-JP body,
.locale-ja-JP .font-sans {
  font-family: Trident, Inter, "Meiryo UI", Meiryo, "Hiragino Maru Gothic Pro", "MS UI Gothic", "MS PGothic", "MS Gothic", "Microsoft YaHei", SimHei, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.locale-ja-JP .font-brand {
  font-family: Trident, Newsreader, "Meiryo UI", Meiryo, "Hiragino Maru Gothic Pro", "MS UI Gothic", "MS PGothic", "MS Gothic", "Microsoft YaHei", SimHei, 'Times New Roman', Times, serif !important;
}

.locale-ja-JP .italic {
  font-style: normal;
}
