/* purgecss start ignore */
@layer utilities {
  .Line {
    @apply isolate my-40;
  }

  @media (--r-minL) {
    .Line {
      @apply my-60;
    }
  }

  .Line .Line:first-child {
    @apply mt-0;
  }

  .Line .Line:last-child {
    @apply mb-0;
  }

  .Line-Immersive,
  .Line-ProductInformation {
    @apply m-0;
  }

  .Line-Immersive + .Line-Search {
    @apply -mt-40;
  }

  .Line-Immersive + .Line-Search {
    @apply -mt-40;
  }

  .Line-Immersive + .Line-Introduction,
  .Line-Immersive + .Line-SubmissionStatus,
  .Line-Immersive + .Line-PriceTicket,
  .Line-Immersive + .Line-ButtonTicket,
  .Line-Immersive + .Line-PriceTicketCustom,
  .Line-ProductInformation + .Line-DownloadLinksList {
    @apply mt-0;
  }

  .Line-Immersive + .Line-TitleAndText {
    @apply mt-20;
  }

  .Line-Breadcrumb {
    @apply my-30;
  }

  .Line-Breadcrumb + .Line {
    @apply mt-30;
  }

  .Line-ButtonTicket {
    @apply relative z-2;
  }

  .Line-Immersive + .Line-PriceTicketCustom,
  .Line-Immersive + .Line-ButtonTicket,
  .Line-Immersive + .Line-PriceTicket {
    margin-top: -4.375rem;
  }

  .Line-Immersive + .Line-PriceRemote {
    margin-top: -5rem;
  }

  @media (--r-minL) {
    .Line-Immersive + .Line-PriceTicketCustom,
    .Line-Immersive + .Line-ButtonTicket,
    .Line-Immersive + .Line-PriceTicket {
      margin-top: -1.875rem;
    }

    .Line-Immersive + .Line-PriceRemote {
      margin-top: -2rem;
    }
  }

  .Line-ProductTeaser + .Line-ProductTeaser {
    @apply border-t-1 border-lightGrey pt-40;
  }

  @media (--r-minS) {
    .Line-ProductTeaser + .Line-ProductTeaser {
      @apply pt-80;
    }
  }

  .Line-ProductsFilters,
  .Line-ProductsGrid {
    @apply relative z-1;
  }

  .Line-Immersive + .Line-ProductsFilters {
    margin-top: -1.875rem;
  }

  .Line-ProductsGrid {
    @apply mb-80;
  }
}
/* purgecss end ignore */
