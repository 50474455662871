/* purgecss start ignore */
.arrow {
  position: relative;
}

.arrow::before {
  position: absolute;
  bottom: -15px;
  left: 50%;
  z-index: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-right: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  content: '';
  background-color: white;
  transform: rotate(45deg) translateX(-50%);
}

.arrow-down,
.arrow-down-top,
.arrow-up,
.arrow-downLeft,
.arrow-downRight,
.arrow-upRight {
  position: relative;
}

.arrow-down::after,
.arrow-down-top::after,
.arrow-up::after,
.arrow-bg::before {
  @apply absolute border-arrow border-solid border-transparent h-0 left-1/2 pointer-events-none transform -translate-x-1/2 w-0;

  content: '';
}

.arrow-downLeft::after,
.arrow-downRight::after,
.arrow-upRight::after {
  @apply absolute border-arrow border-solid border-transparent h-0 pointer-events-none transform -translate-x-1/2 w-0;

  content: '';
}

.arrow-downRight::after,
.arrow-downRight.arrow-bg::before,
.arrow-upRight::after,
.arrow-upRight.arrow-bg::before {
  left: 80%;
}

[dir="rtl"] .arrow-downRight::after,
[dir="rtl"] .arrow-downRight.arrow-bg::before,
[dir="rtl"] .arrow-upRight::after,
[dir="rtl"] .arrow-upRight.arrow-bg::before {
  left: 20%;
}

.arrow-downLeft::after,
.arrow-downLeft.arrow-bg::before {
  left: 20%;
}

[dir="rtl"] .arrow-downLeft::after,
[dir="rtl"] .arrow-downLeft.arrow-bg::before {
  left: 80%;
}

.arrow-down::after,
.arrow-down.arrow-bg::before,
.arrow-downLeft::after,
.arrow-downLeft.arrow-bg::before,
.arrow-downRight::after,
.arrow-downRight.arrow-bg::before {
  top: 100%;
  border-top-color: currentColor;
}

.arrow-down-top::after {
  top: 0;
  border-top-color: currentColor;
}

.arrow-up::after,
.arrow-up.arrow-bg::before,
.arrow-upRight::after,
.arrow-upRight.arrow-bg::before {
  bottom: 100%;
  border-bottom-color: currentColor;
}

.arrow-none::before,
.arrow-none::after {
  content: none;
}

@media (--r-minM) {
  .md\:arrow-none::before,
  .md\:arrow-none::after {
    content: none;
  }
}

@media (--r-minL) {
  .lg\:arrow-none::before,
  .lg\:arrow-none::after {
    content: none;
  }
}

/* bg */
.arrow-down.arrow-bg::before,
.arrow-downLeft.arrow-bg::before,
.arrow-downRight.arrow-bg::before {
  margin-top: 1px;
}

.arrow-up.arrow-bg::before,
.arrow-upRight.arrow-bg::before {
  margin-bottom: 1px;
}

.arrow-down.arrow-bg-large::before,
.arrow-downLeft.arrow-bg-large::before,
.arrow-downRight.arrow-bg-large::before {
  margin-top: 2px;
}

.arrow-up.arrow-bg-large::before,
.arrow-upRight.arrow-bg-large::before {
  margin-bottom: 2px;
}

/* colors */
.arrow-orange::after,
.arrow-bg-orange::before {
  @apply text-orange;
}

.arrow-sienna::after,
.arrow-bg-sienna::before {
  @apply text-sienna;
}

.arrow-saffron::after,
.arrow-bg-saffron::before {
  @apply text-saffron;
}

.arrow-ultramarine::after,
.arrow-bg-ultramarine::before {
  @apply text-ultramarine;
}

.arrow-gm-turquoise::after,
.arrow-bg-gm-turquoise::before {
  @apply text-gm-turquoise;
}

.arrow-gm-gold::after,
.arrow-bg-gm-gold::before {
  @apply text-gm-gold;
}

.arrow-gm-platinum::after,
.arrow-bg-gm-platinum::before {
  @apply text-gm-platinum;
}

.arrow-sand::after,
.arrow-bg-sand::before {
  @apply text-sand;
}

.arrow-lightSand::after,
.arrow-bg-lightSand::before {
  @apply text-lightSand;
}

.arrow-gm-silver::after,
.arrow-bg-gm-silver::before {
  @apply text-gm-silver;
}

.arrow-blueWave::after,
.arrow-bg-blueWave::before {
  @apply text-blueWave;
}

.arrow-black::after,
.arrow-bg-black::before {
  @apply text-black;
}

.arrow-red::after,
.arrow-bg-red::before {
  @apply text-red;
}

.arrow-white::after,
.arrow-bg-white::before {
  @apply text-white;
}

.arrow-lightGrey::after,
.arrow-bg-lightGrey::before {
  @apply text-lightGrey;
}

.arrow-grey::after,
.arrow-bg-grey::before {
  @apply text-grey;
}

.arrow-mediumGrey::after,
.arrow-bg-mediumGrey::before {
  @apply text-mediumGrey;
}

.arrow-darkGrey::after,
.arrow-bg-darkGrey::before {
  @apply text-black;
}

.arrow-success::after,
.arrow-bg-success::before {
  @apply text-success;
}

/* large */
.arrow-large::after,
.arrow-large::before {
  @apply border-arrow-large;
}

/* small */
.arrow-small::after,
.arrow-small::before {
  @apply border-arrow-small;
}

/* navigation */
.navigation-arrow::before,
.navigation-arrow::after {
  position: absolute;
  bottom: -1px;
  left: 50%;
  z-index: 1;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid theme('colors.white');
  border-left: 8px solid transparent;
  content: '';
  opacity: 0;
  transform: translateX(-50%);
  transition: all ease-in 0.1s;
}

.navigation-arrow::before {
  bottom: 0;
  border-bottom-color: theme('colors.grey');
}

.navigation-arrow.navigation-arrow-active::before,
.navigation-arrow.navigation-arrow-active::after {
  opacity: 100;
}

@media (--r-minL) {
  .navigation-arrow--overlap::before {
    bottom: 5px;
  }

  .navigation-arrow--overlap::after {
    bottom: 4px;
  }
}

/* remote */
.criteria-arrow::before,
.criteria-arrow::after {
  position: absolute;
  bottom: -1px;
  left: 50%;
  z-index: 5;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid theme('colors.white');
  border-left: 8px solid transparent;
  content: '';
  transform: translateX(-50%);
}

.criteria-arrow::before {
  bottom: 0;
  border-bottom-color: theme('colors.grey');
}

/* purgecss end ignore */
